<template>
  <div class="albumDownload" :class="[isIOS?'heightIos':'heightAnd']">
    <div class="title-box">
      <h3>手册下载</h3>
      <!-- <p>新云网科技集团股份有限公司</p> -->
    </div>
    <div class="downloadImg-box">
      <img src="../../assets/images/website/donwload-1.png" class="img1">
      <div class="btn-box">
        <div v-if="isShowBtn==true" class="btn" @click="getBrowserType">
          <span>点击下载 ></span>
        </div>
        <!-- <span v-if="isShowBtn==true" @touchend="getBrowserType">点击下载 ></span> -->
        <a v-else href="https://file-statics.cunwedu.com.cn/doc/智慧教育手册.pdf?response-content-type=application/octet-stream" target="_blank">
          <div class="btn">点击下载 ></div>
        </a>
      </div>
    </div>
    <div class="downloadImg-box">
      <img src="../../assets/images/website/donwload-2.png" class="img2">
      <div class="btn-box">
        <!-- @click="donwloadAttractInvestment" -->
        <div v-if="isShowBtn==true" class="btn" @click="getBrowserType">
          <span>点击下载 ></span>
        </div>
        <a v-else href="https://file-statics.cunwedu.com.cn/doc/招商手册.pdf?response-content-type=application/octet-stream" target="_blank">
          <div class="btn">
            点击下载 >
          </div>
        </a>
        <!-- <span v-if="isShowBtn==true" @click="getBrowserType">点击下载 ></span> -->
      </div>
    </div>
    <img v-if="isShowIos==2" src="../../assets/images/website/info.png" class="guide-img">
    <img v-if="isShowIos==3" src="../../assets/images/website/ios.png" class="guide-img">
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowIos: 1,
      isShowBtn: false,
      isIOS: false
    };
  },
  mounted() {
    // this.getBrowserType();
    this.isShowBtn = this.isWeiXin();
    // const u = navigator.userAgent;
    this.isIOS = this.isIOSFuc();
  },
  methods: {
    donwloadUrl() {
      window.location.href = `https://file-statics.cunwedu.com.cn/doc/智慧教育手册.pdf`;
    },
    getBrowserType() {
      const is_weixin = this.isWeiXin();
      // const u = navigator.userAgent;
      //   const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      // const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (is_weixin) {
        if (this.isIOS) {
          this.isShowIos = 3;
        } else {
          this.isShowIos = 2;
        }
      }
    },
    isIOSFuc() {
      const u = navigator.userAgent;
      //   const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      return isIOS;
    },
    isWeiXin() {
      const ua = navigator.userAgent;
      // 是否是微信浏览器
      if (/(micromessenger)/i.test(ua)) {
        // 是否电脑微信或者微信开发者工具
        if (/(WindowsWechat)/i.test(ua) || /(wechatdevtools)/i.test(ua)) {
          return false;
        } else {
          // 手机微信打开的浏览器
          return true;
        }
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 960px) {
  .albumDownload{
    max-width: 677px;
    margin: 0 auto;
    // height: 100vh;
    // overflow: hidden;
  }
}
@media screen and (max-width: 960px) {
  .heightAnd{
    height: 100vh;
  }
  .heightIos{
    height: 100%;
  }
}
.albumDownload{
    background: #fff;
    padding: 15px 15px 30px 15px;
    position: relative;
    .title-box{
        h3{
            font-size: 24px;
            font-weight: 800;
            color: #000000;
        }
        p{
            font-size: 16px;
            font-weight: bold;
            color: #5E6D93;
            letter-spacing: 1px;
            margin-top: 5px;
        }
    }
    .downloadImg-box{
        margin-top: 15px;
        position: relative;
        text-align: center;
        .img1{
            width: 100%;
            height: 100%;
        }
        .img2{
            width: 100%;
            height: 100%;
        }
        .btn-box{
            position: absolute;
            bottom: 15px;
            transform: translate(-50%,0);
            left: 50%;
            .btn{
                text-align: center;
                width: 180px;
                height: 30px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-image: url('../../assets/images/website/donwload-3.png');
                font-size: 15px;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 25px;
            }
        }
    }
    .guide-img{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

    }
}
</style>
